export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  extralink: boolean;
  submenu: RouteInfo[];
}
export const ROUTES: RouteInfo[] =
 [
    {
    path: '/home',
    title: 'Dashboard',
    icon: 'icon-Dashboard',
    class: '',
    extralink: false,
    submenu: []
  },

  {
    path: '',
    title: 'User Management',
    icon: 'icon-User',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/manageclientuser',
        title: 'Manage Client User',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/manageqcuser',
        title: 'Manage QC User',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/managefielduser',
        title: 'Manage Field User',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Clients',
    icon: 'icon-People-onCloud',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/managebranches',
        title: 'Manage Branches',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/manageclients',
        title: 'Manage Clients',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/managejoblist',
        title: 'Manage Job List For Clients',
        icon: 'icon-Dashboard',
        class: '',
        extralink: false,
        submenu: []
      },

    ]
  },
  
  {
    path: '',
    title: 'Manage Job Types',
    icon: 'icon-Management',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/managejobtype',
        title: 'Manage Job Type',
        icon: 'icon-Dashboard',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/managecretria',
        title: 'Manage Cretria',
        icon: 'icon-Dashboard',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/managedamages',
        title: 'Manage Damages',
        icon: 'icon-Dashboard',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/manageproducts',
        title: 'Manage Products',
        icon: 'icon-Dashboard',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: '/agentsperformance',
    title: 'Agents Perfomance',
    icon: 'icon-Line-Chart2',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/downloadinvoice',
    title: 'Download Invoice',
    icon: 'icon-Download',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/performanceinvoice',
    title: 'Performer Invoice',
    icon: 'icon-Dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/taxinvoice',
    title: 'Tax Invoice',
    icon: 'icon-File-ClipboardFileText',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/sendapklink',
    title: 'Send APK Link',
    icon: 'icon-Link',
    class: '',
    extralink: false,
    submenu: []
  },

];

export const QCROUTES: RouteInfo[] =
 [
    {
    path: '/qcdashboard',
    title: 'Dashboard',
    icon: 'icon-Dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
];

export const INSPROUTES: RouteInfo[] =
 [
    {
    path: '/inspection',
    title: 'Dashboard',
    icon: 'icon-Dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
];


