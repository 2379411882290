import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { map } from "rxjs/internal/operators/map";
import { tap } from "rxjs/internal/operators/tap";
import { environment } from "src/environments/environment";
import { CommonFunctionService } from "./commonfunction.service";
@Injectable({
  providedIn: "root",
})
export class WebService {
  serviceurl = environment.serviceUrl;
  constructor(
    private toastr: ToastrService,
    public _httpClient: HttpClient,
    private router: Router,
    private CommonService: CommonFunctionService,
    @Inject(PLATFORM_ID) platformId: string
  ) { }

  // public async login(username: any, password: any, id: any): Promise<any> {
  //   try {
  //     return new Promise((resolve, reject) => {
  //       const url = `${this.serviceurl}/Registration/LoginMethod`;
  //       const body = {
  //         "uid": username,
  //         "role_id": id,
  //         "password": password
  //       };
  //       return this._httpClient
  //         .post<any>(url, body)
  //         .pipe(map((d: any) => (d.Status_cd === "1" ? d.ds.Table[0] : false)))
  //         .subscribe((r: any) => resolve(r), reject);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async Token(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceurl}/Token`;
        const body = {

          "username": "Sympl",
          "password": "Sympl159"
          // "username": "ezee_admin",
          // "password": "ezee_admin123"

          // "username": "integrate_admin",
          // "password": "integrate_admin@123"
        };
        return this._httpClient.post<any>(url, body)
          .pipe(
            map(d => (d.code === 1) ? d.document.accessToken : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
}

