export const environment = {
  production: false,
  // serviceUrl: 'https://meanhost.in/wsv2api/api',
  // serviceUrl:'https://ezeeapi.meanhost.in/v1/api',
  serviceUrl:'https://demointegrate.symplfy.com/v1/api',
  // serviceUrl:'https://api.symplfy.com/v1/api',

//  MicroServices: 'https://ssc.meanhost.in/services/v1/api'
   // MicroServices: 'http://183.82.118.97/csstapi/v1/api'
};
