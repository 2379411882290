import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { first, startWith, take } from "rxjs/operators";
import { CommonFunctionService } from "src/app/services/commonfunction.service";
import { WebService } from "src/app/services/Webservice.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  submitted: boolean;
  loginvalid = false;
  hide = true;
  Role_id: any;
  user_data:any=[]
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private service: WebService,
    public CommonService: CommonFunctionService
  ) { }
  ngOnInit(): void {
    this.loginform = this.formbuilder.group({
      emial: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginform.invalid) {
      return;
    } else {
      this.loginvalid = true;
      this.service.Token().then(r => {
        if (r) {
          localStorage.setItem('Prinsezeetoken', r);
          if (this.loginform.get("password").value == "admin@1234") {

            this.user_data=[{
              Role_id:101,
              Role_name:"Administrator"
            }]

            localStorage.setItem("Role_id", JSON.stringify(this.user_data));
            this.CommonService.showSuccess("Login Successfully", "Success");
            this.loginvalid = false;
            this.router.navigateByUrl("home");
          } else if (this.loginform.get("password").value == "qc@1234") {
            this.user_data=[{
              Role_id:102,
              Role_name:"QC"
            }]
            localStorage.setItem("Role_id", JSON.stringify(this.user_data));
            this.CommonService.showSuccess("Login Successfully", "Success");
            this.loginvalid = false;
            this.router.navigateByUrl("qcdashboard");
          }
          else if (this.loginform.get("password").value == "insp@1234") {
            this.user_data=[{
              Role_id:103,
              Role_name:"Inspector"
            }]
            localStorage.setItem("Role_id", JSON.stringify(this.user_data));
            this.CommonService.showSuccess("Login Successfully", "Success");
            this.loginvalid = false;
            this.router.navigateByUrl("inspection");
          } else {
            this.loginvalid = false;
            this.CommonService.showError("Invalid Password", "Error");
          }
        }
      }).catch(err => console.log(err))
    }
  }
  // ===============================================================================================
}
